import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  BaseTask,
  CaseSettings, ClientSettings,
  ConfirmDialogModel, County, CountyFeeSetting,
  DocumentTemplate,
  EvictionUpdateConfirmationMessage,
  StepCompletionModel,
  Tenant,
  User, WorkflowStep,
} from '@ee/common/models';
import {lastValueFrom, Observable, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {FlatFeeType, UserRoles} from '@ee/common/enums';
import {ClientService, CountyFeeSettingsService, EvictionService, FormService, UserService} from '@ee/common/services';
import {generateCourtTaskForm} from '@ee/common/forms';
import {generateAttorneyFeeBillable, generateCountyFeeBillable, GenerateStepCompletedForm} from './step-completed.form';

@Component({
  selector: 'ee-complete-workflow-step',
  template: `
    <div *ngIf="form" class="main-step-wrapper" [formGroup]="form" [ngClass]="{'vertical': forceVertical}">
      <div class="step-completion-fields">
        <div *ngIf="!hideLabel" class="mb-4">Fill out the form and submit to complete the current step.</div>
        <div class="custom-fields" *ngIf="customFieldsArray?.length">
          <ee-custom-field class="mb-2" *ngFor="let fieldForm of customFieldsArray?.controls"
                           [form]="$any(fieldForm)"></ee-custom-field>
        </div>
        <div class="flex flex-col justify-end items-center mb-2"
             *ngIf="(forceAddCourtTaskButton || nextStep?.show_new_court_date_form) && !nextStep?.new_court_date_required && !hasCourtDateForm">
          <button mat-flat-button type="button" color="primary" (click)="addCourtDateForm()">
            Create New Court Task
          </button>
        </div>
        <div *ngIf="(!hideComments || !hideNotes) && (form.get('comment') || form.get('notes'))"
             class="flex flex-row justify-stretch items-stretch mb-2">
          <mat-form-field *ngIf="!hideComments && form.get('comment')"
                          class="flex-1 mr-2 compact double-column" appearance="fill">
            <mat-label>Save a message for the client</mat-label>
            <textarea matInput rows="4" formControlName="comment"></textarea>
          </mat-form-field>
          <mat-form-field *ngIf="!hideNotes && form.get('notes')"
                          appearance="fill" class="compact flex-1 double-column">
            <mat-label>Save a note visible only to your firm</mat-label>
            <textarea matInput rows="4" formControlName="notes"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="workflow-generators">
        <div class="highlighted-box" *ngIf="hasJudgementForm" [ngClass]="{'mb-4': hasBillableItems || hasDocumentTemplates || hasCourtDateForm}">
          <div class="highlighted-box-label">
            <div>Judgment: {{judgementResultForm.get('name').value}}</div>
            <div>
              {{judgementResultForm.get('due_date').value | dateTimeFromIso | dateTimeToFormat:'MMM d, yyyy'}}

              <span class="ml-1" *ngIf="judgementResultForm.get('legacy').value && !!judgementResultForm.get('legacy_court_time').value">
                {{judgementResultForm.get('legacy_court_time').value}}
              </span>

              <span class="ml-1" *ngIf="!!judgementResultForm.get('show_time').value && !judgementResultForm.get('legacy').value">
                {{judgementResultForm.get('due_date').value | dateTimeFromIso | dateTimeToFormat:'t'}}
              </span>

            </div>
          </div>
          <ee-court-judgement class="section-wrapper mb-4" [form]="judgementResultForm" [hideComment]="true"
                              [caseSettings]="caseSettings"></ee-court-judgement>
        </div>
        <div class="highlighted-box" *ngIf="hasCourtDateForm" [ngClass]="{'mb-4': hasBillableItems || hasDocumentTemplates}">
          <div class="highlighted-box-label">
            <span>New Court Task</span>
            <span *ngIf="(forceAddCourtTaskButton || nextStep?.show_new_court_date_form) && !nextStep?.new_court_date_required"
                  (click)="removeCourtDateForm()" class="remove">
              Remove Task
            </span>
          </div>
          <ee-task-form class="mb-2" [taskForm]="courtDateForm"></ee-task-form>
        </div>

        <div class="highlighted-box" [ngClass]="{'mb-4': hasDocumentTemplates}"
             *ngIf="hasBillableItems">
          <div class="highlighted-box-label">Auto-Billables</div>
          <ee-workflow-step-billables class="mb-2" [overrideAddBillable]="billablesVisibility === 'visible'"
                                      [billableItemsArray]="billableItemsArray"
                                      [workflowStep]="this.nextStep">
          </ee-workflow-step-billables>
        </div>
        <div class="highlighted-box" *ngIf="hasDocumentTemplates">
          <div class="highlighted-box-label">Generate Documents</div>
          <ee-document-selector [vertical]="true"
                                [visibleDocumentTemplates]="$any(form.get('document_templates'))"
                                [hiddenDocumentTemplates]="$any(form.get('hidden_document_templates'))"/>
        </div>
      </div>
    </div>
  `,
  styles: [`
    @import 'responsive.mixin.scss';
    @import 'components/_color-palette';

    .custom-fields {
      display: grid !important;
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
      grid-auto-rows: 1fr;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      overflow: hidden;

      @media screen and (min-width: $break-lg) {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      }
    }

    .main-step-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;

      &.vertical {
        flex-direction: column-reverse;

        .step-completion-fields {
          margin-right: 0;
          margin-top: 16px;
        }
      }

      .step-completion-fields {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        margin-bottom: 10px;
      }

      .workflow-generators {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        flex: 1;
      }

      @media screen and (min-width: $break-lg) {
        flex-direction: row;

        .step-completion-fields {
          margin-right: 15px;
          margin-bottom: 0;
        }
      }
    }

    .highlighted-box {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      background-color: #F5F5F5;
      padding: 15px;

      .highlighted-box-label {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 10px;

        .remove {
          text-transform: capitalize;
          text-decoration: underline;
          font-size: 12px;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteWorkflowStepComponent implements OnInit, OnDestroy, OnChanges {
  attorneyUsers$: Observable<User[]>;
  forms$: Observable<DocumentTemplate[]>;
  evictionUpdateMessage: ConfirmDialogModel;
  hasDocumentTemplates = false;
  hasCourtDateForm = false;
  hasJudgementForm = false;
  hasBillableItems = false;
  form: UntypedFormGroup;
  private subs: Subscription[] = [];

  public message = ' ';

  @Input({required: true}) caseSettings: CaseSettings;
  @Input({required: true}) nextStep: WorkflowStep;
  @Input({required: false}) clientId: string | null;
  @Input({required: false}) caseId: string | null;
  @Input({required: false}) hideComments = false;
  @Input({required: false}) hideNotes = false;
  @Input({required: false}) nextCourtTask: BaseTask | null;
  @Input({required: false}) county: County | null;
  @Input({required: false}) tenants: Tenant[] = [];
  @Input({required: false}) countyFeeSetting: CountyFeeSetting;
  @Input({required: false}) parentForm: UntypedFormGroup | null;
  @Input({required: false}) hideLabel = false;
  @Input({required: false}) forceVertical = false;
  @Input({required: false}) forceJudgementPlanCompletion = false;
  @Input({required: false}) forceAddCourtTaskButton = false;
  @Input({required: false}) documentTemplateVisibility: 'visible' | 'hidden' | 'default' = 'default';
  @Input({required: false}) billablesVisibility: 'visible' | 'hidden' | 'default' = 'default';
  @Output() onSave = new EventEmitter<StepCompletionModel>();

  constructor(private fb: UntypedFormBuilder,
              public formService: FormService,
              public dialog: MatDialog,
              public evictionService: EvictionService,
              public userService: UserService,
              public clientService: ClientService,
              public countyFeeSettingsService: CountyFeeSettingsService,
              public changeDetectorRef: ChangeDetectorRef) {
    this.evictionUpdateMessage = EvictionUpdateConfirmationMessage();
  }

  ngOnInit() {
    this.forms$ = this.formService.getAllForms();
    this.attorneyUsers$ = this.userService.getUsers(null, [UserRoles.ATTORNEY], true);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const changeKeys = Object.keys(changes);
    if (changeKeys.length === 1 && changeKeys[0] === 'county') {
      this.replaceCountyBillableItem(this.nextStep, changes['county'].currentValue);
      this.changeDetectorRef.detectChanges();
    } else {
      this.hasDocumentTemplates = false;
      this.hasCourtDateForm = false;
      this.hasJudgementForm = false;
      this.hasBillableItems = false;
      if (this.county && this.countyFeeSetting?.county_id !== this.county?.id) {
        this.countyFeeSetting = await lastValueFrom(this.countyFeeSettingsService.getByCounty(this.county));
      }
      this.subs.push(this.clientService.getClientSettings(this.clientId).subscribe((clientSettings: ClientSettings | null) => {
        this.form = GenerateStepCompletedForm(this.fb, this.nextStep, this.county, this.tenants ?? [], this.countyFeeSetting,
          clientSettings, this.nextCourtTask, this.forceJudgementPlanCompletion);
        if (this.parentForm) {
          this.parentForm.setControl('step_completion_details', this.form);
        }
        this.hasDocumentTemplates = (this.nextStep?.document_templates?.length > 0 ||
            this.nextStep?.hidden_document_templates?.length > 0 || this.documentTemplateVisibility === 'visible') &&
          this.documentTemplateVisibility !== 'hidden';
        this.hasBillableItems = (this.nextStep?.billable_items?.length > 0 || this.billablesVisibility === 'visible') &&
          this.billablesVisibility !== 'hidden';
        this.hasCourtDateForm = this.nextStep?.show_new_court_date_form && !!this.courtDateForm;
        this.hasJudgementForm = !!this.nextCourtTask && !this.nextCourtTask?.completed_date && !!this.judgementResultForm;
        this.changeDetectorRef.detectChanges();
      }));
    }
  }

  get customFieldsArray(): UntypedFormArray {
    return this.form?.get('custom_fields') as UntypedFormArray;
  }

  get billableItemsArray(): UntypedFormArray {
    return this.form?.get('billable_items') as UntypedFormArray;
  }

  get courtDateForm(): UntypedFormGroup | null {
    return this.form?.get('new_court_task') as UntypedFormGroup | null;
  }

  get judgementResultForm(): UntypedFormGroup | null {
    return this.form?.get('judgement_result') as UntypedFormGroup | null;
  }

  addCourtDateForm() {
    let taskName = 'Court Appearance';
    if (this.hasJudgementForm) {
      taskName = 'Followup ' + taskName;
    }
    this.form.addControl('new_court_task', generateCourtTaskForm(this.fb, null, taskName));
    this.hasCourtDateForm = true;
  }

  removeCourtDateForm() {
    this.form.removeControl('new_court_task');
    this.hasCourtDateForm = false;
  }

  public save(): void {
    if (this.form.invalid) {
      this.message = 'Please fix the errors in the form to complete the step.';
      return;
    }

    const value = this.form.value as StepCompletionModel;
    value.case_id = this.caseId;
    value.step = this.nextStep?.step;
    this.onSave.emit(value);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  async replaceCountyBillableItem(nextStep: WorkflowStep, county: County) {
    // remove item of type
    this.billableItemsArray.controls.forEach((item) => {
      if (item.get('flat_fee_type')?.value === FlatFeeType.COUNTY_FEE) {
        this.billableItemsArray.removeAt(this.billableItemsArray.controls.indexOf(item));
      }
    });

    // find if workflow step requires county fee
    const definedBillable = nextStep?.billable_items.find((item) => item.flat_fee_type === FlatFeeType.COUNTY_FEE);
    if (definedBillable) {
      this.countyFeeSetting = await lastValueFrom(this.countyFeeSettingsService.getByCounty(county));
      if (this.countyFeeSetting) {
        this.billableItemsArray.push(generateCountyFeeBillable(this.fb, this.countyFeeSetting, this.county, this.tenants));
        this.changeDetectorRef.detectChanges();
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  async replaceAttorneyBillableItem(nextStep: WorkflowStep, clientId: string | null) {
    // remove item of type
    this.billableItemsArray.controls.forEach((item) => {
      if (item.get('flat_fee_type')?.value === FlatFeeType.CLIENT_ATTORNEY_FEE) {
        this.billableItemsArray.removeAt(this.billableItemsArray.controls.indexOf(item));
      }
    });

    // find if workflow step requires attorney fee
    const definedBillable = nextStep?.billable_items.find((item) => item.flat_fee_type === FlatFeeType.CLIENT_ATTORNEY_FEE);
    if (definedBillable && clientId) {
      const clientSettings = await lastValueFrom(this.clientService.getClientSettings(clientId));
      this.billableItemsArray.push(generateAttorneyFeeBillable(this.fb, definedBillable, clientSettings));
      this.changeDetectorRef.detectChanges();
    }
    this.changeDetectorRef.detectChanges();
  }

}
