import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CustomFieldType} from '@ee/common/enums';
import {CaseSettings} from '@ee/common/models';

@Component({
  selector: 'ee-court-judgement',
  template: `
    <div [formGroup]="form" *ngIf="!!form" class="flex flex-col justify-start items-stretch">
      <div class="in-row-grid wider mb-2">
        <mat-form-field class="compact" [ngClass]="{'w-full mb-4': forceVertical}"
                        *ngIf="caseSettings?.judgement_options?.length">
          <mat-label>Judgment</mat-label>
          <mat-select formControlName="judgement">
            <mat-option *ngFor="let option of caseSettings.judgement_options" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-hint>{{form?.get('judgement').hasValidator(Validators.required) ? 'Required' : 'Optional'}}</mat-hint>
        </mat-form-field>
        <mat-form-field class="compact" [ngClass]="{'w-full mb-4': forceVertical}">
          <mat-label>Judgment Amount</mat-label>
          <span matPrefix>$ &nbsp;</span>
          <input matInput type="number" formControlName="judgement_amount">
          <mat-hint>{{form?.get('judgement_amount').hasValidator(Validators.required) ? 'Required' : 'Optional'}}</mat-hint>
        </mat-form-field>
      </div>
      <div class="in-row-grid wider mb-2">
        <mat-form-field class="compact" [ngClass]="{'w-full mb-4': forceVertical}">
          <mat-label>Judgment Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="judgement_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-hint>{{form?.get('judgement_date').hasValidator(Validators.required) ? 'Required' : 'Optional'}}</mat-hint>
        </mat-form-field>
        <mat-form-field class="compact" *ngIf="caseSettings?.court_result_options?.length"
                        [ngClass]="{'w-full mb-4': forceVertical}">
          <mat-label>Docket Result</mat-label>
          <mat-select formControlName="court_result">
            <mat-option *ngFor="let option of caseSettings.court_result_options" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-hint>{{form?.get('court_result').hasValidator(Validators.required) ? 'Required' : 'Optional'}}</mat-hint>
        </mat-form-field>
      </div>
      <div *ngIf="!hideComment">
        <mat-form-field appearance="fill" [ngClass]="{'w-full mb-4': forceVertical}">
          <mat-label>Comment</mat-label>
          <textarea matInput rows="4" formControlName="comment"></textarea>
          <mat-error *ngIf="form?.get('comment').hasError('required')">
            Please provide a reason for the change.
          </mat-error>
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>
    </div>
  `,
  styles: ['']
})
export class CourtJudgementComponent implements OnChanges, OnDestroy {
  @Input() caseSettings: CaseSettings | null;
  @Input({required: true}) form: UntypedFormGroup;
  @Input() forceVertical = false;
  @Input() hideComment = false;
  public name: string;

  subs: Subscription[] = [];

  constructor() {
  }

  ngOnChanges() {
    if (this.form && this.caseSettings?.judgement_options?.length) {
      this.form?.get('judgement').setValue(this.caseSettings.judgement_options[0]);
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  protected readonly CustomFieldType = CustomFieldType;
  protected readonly Validators = Validators;
}
