import { Auditing } from './auditing.model';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BillableItemType, FlatFeeType } from '@ee/common/enums';

export interface BillableItem extends Auditing {
  id?: string;
  case_id?: string;
  type?: BillableItemType;
  flat_fee_type?: FlatFeeType;
  date_incurred?: Date;
  description?: string;
  save_description?: boolean;
  qty?: number;
  rate?: number;
  amount?: number;
  paid?: number;
  invoice_id?: string;
  invoice_number?: string;
  invoice_add_date?: string;
  street_address?: string;
  tenants?: string;
  selected?: boolean;
  custom?: boolean;
  deleted?: boolean;
  deleted_by?: string;
  deleted_date?: string;
}

export type BillableItemFormType = FormGroup<{
  id: FormControl<string | null>;
  case_id: FormControl<string | null>;
  type: FormControl<BillableItemType>;
  flat_fee_type: FormControl<FlatFeeType | null>;
  date_incurred: FormControl<Date>;
  description: FormControl<string | null>;
  save_description: FormControl<boolean>;
  qty: FormControl<number | null>;
  rate: FormControl<number | null>;
  amount: FormControl<number | null>;
  paid: FormControl<number | null>;
  invoice_id: FormControl<string | null>;
  invoice_add_date: FormControl<string | null>;
}>

export function GenerateBillableItemForm(fb: FormBuilder, value: BillableItem = { type: BillableItemType.FLAT_FEE },
  autoBillableSetup = false): BillableItemFormType {
  const form: BillableItemFormType = fb.group({
    id: new FormControl<string | null>(null, { nonNullable: false }),
    case_id: new FormControl<string | null>(null),
    type: new FormControl<BillableItemType>(BillableItemType.FLAT_FEE, Validators.required),
    flat_fee_type: new FormControl<FlatFeeType | null>(null),
    date_incurred: new FormControl<Date>(new Date(), autoBillableSetup ? null : Validators.required),
    description: new FormControl<string | null>(null, Validators.required),
    save_description: new FormControl<boolean>(false),
    qty: new FormControl<number | null>(null),
    rate: new FormControl<number | null>(null),
    amount: new FormControl<number | null>(null, Validators.required),
    paid: new FormControl<number | null>(null),
    invoice_id: new FormControl<string | null>(null),
    invoice_add_date: new FormControl<string | null>(null)
  });

  if (value) {
    form.patchValue(value);
    if (!form.value.date_incurred) { // set the date incurred to today
      form.get('date_incurred').setValue(new Date());
    }
  }

  return form;
}

export function GenerateAutoBillableForm(fb: UntypedFormBuilder, value: BillableItem = null) {
  const amountValidator = !value || (value.flat_fee_type !== FlatFeeType.COUNTY_FEE && value.flat_fee_type !== FlatFeeType.CLIENT_ATTORNEY_FEE)
    ? Validators.required : null;
  const form = fb.group({
    type: [null, Validators.required],
    flat_fee_type: [null],
    description: [null, Validators.required],
    amount: [null, amountValidator]
  });

  if (value) {
    form.reset(value);
  }

  return form;
}
